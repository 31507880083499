const mainMenuData = [
  {
    title: "Armarios",
    mobileTitle: "Armarios",
    mobileSubmenuTitle: "Armarios:",
    subMenuData: [
      {
        title: "Armario de cultivo",
        url: "/armarios-cultivo-interior",
      },
      {
        title: "Propagador de esquejes",
        url: "/armarios-cultivo-interior/propagador-esquejes",
      },
    ],
  },
  {
    title: "Clima",
    mobileTitle: "Control Clima",
    mobileSubmenuTitle: "Controladores Clima:",
    url: "/control-clima",
    subMenuData: [
      {
        title: "Control de Temperatura",
        url: "/control-clima/controlador-temperatura",
      },
      {
        title: "Control de Humedad",
        url: "/control-clima/controlador-humedad",
      },
    ],
  },
  {
    title: "Co2",
    mobileTitle: "Co2",
    mobileSubmenuTitle: "≫ Generador Co2",
    url: "/generador-co2",
    subMenuData: [],
  },
  {
    title: "Ventilación",
    mobileTitle: "Ventilación",
    mobileSubmenuTitle: "Kits ventilación:",
    subMenuData: [
      {
        title: "Ventilador",
        url: "/ventilacion/ventilador",
      },
      {
        title: "Aire acondicionado",
        url: "/ventilacion/aire-acondicionado",
      },
      {
        title: "Extractor",
        url: "/ventilacion/extractor-aire",
      },
    ],
  },
  {
    title: "Secaderos",
    mobileTitle: "Secar-Prensar",
    mobileSubmenuTitle: "Kits secar/prensar:",
    subMenuData: [
      {
        title: "Kit Secado María",
        url: "/secadero-cogollos-marihuana",
      },
      {
        title: "Prensa Rosin",
        url: "/prensa-rosin-barata",
      },
    ],
  },
  {
    title: "Luces",
    mobileTitle: "Iluminación",
    mobileSubmenuTitle: "Kits iluminación:",
    subMenuData: [
      {
        title: "Focos LED",
        url: "/iluminacion/led",
      },
      {
        title: "Balastro",
        url: "/iluminacion/balastro",
      },
    ],
  },
];

export default mainMenuData;
