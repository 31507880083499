import "./App.css";
import React, { Suspense, useEffect } from "react";
import Layout from "./Utils/Layout/Layout";
import { Switch, Route, useLocation } from "react-router-dom";
import Spinner from "./Utils/Spinner/Spinner";

const Home = React.lazy(() => import("./Containers/Home/Home"));

const NotFound404 = React.lazy(() =>
  import("./Containers/NotFound404/NotFound404")
);

const AvisoLegal = React.lazy(() =>
  import("./Containers/AvisoLegal/AvisoLegal")
);

const PoliticaPrivacidad = React.lazy(() =>
  import("./Containers/PoliticaPrivacidad/PoliticaPrivacidad")
);

const PoliticaCookies = React.lazy(() =>
  import("./Containers/PoliticaCookies/PoliticaCookies")
);

const ControlClima = React.lazy(() =>
  import("./Containers/Categories/ControlClima/ControlClima")
);

const Temperatura = React.lazy(() =>
  import(
    "./Containers/Categories/ControlClima/SubCategoryControladorTemperatura/ControladoresTemperatura"
  )
);

const Humedad = React.lazy(() =>
  import(
    "./Containers/Categories/ControlClima/SubCategoryControladorHumedad/ControladoresHumedad"
  )
);

const Co2 = React.lazy(() =>
  import(
    "./Containers/Categories/ControlClima/SubCategoryControladoresCo2/ControladoresCo2"
  )
);

const Armarios = React.lazy(() =>
  import("./Containers/Categories/ControlClima/SubCategoryArmarios/Armarios")
);

const Propagador = React.lazy(() =>
  import(
    "./Containers/Categories/ControlClima/SubCategoryPropagadores/Propagadores"
  )
);

const Secadero = React.lazy(() =>
  import("./Containers/Categories/SecarPrensar/SubCategorySecadero/Secaderos")
);

const PrensaRosin = React.lazy(() =>
  import(
    "./Containers/Categories/SecarPrensar/SubCategoryPrensaRosin/PrensasRosin"
  )
);

const Iluminacion = React.lazy(() =>
  import("./Containers/Categories/Iluminacion/Iluminacion")
);

const Led = React.lazy(() =>
  import("./Containers/Categories/Iluminacion/SubCategoryLed/Leds")
);

const Balastro = React.lazy(() =>
  import("./Containers/Categories/Iluminacion/SubCategoryBalastro/Balastros")
);

const Ventilacion = React.lazy(() =>
  import("./Containers/Categories/Ventilacion/Ventilacion")
);

const Ventilador = React.lazy(() =>
  import(
    "./Containers/Categories/Ventilacion/SubCategoryVentilador/Ventiladores"
  )
);

const AireAcondicionado = React.lazy(() =>
  import(
    "./Containers/Categories/Ventilacion/SubCategoryAireAcondicionado/AiresAcondicionados"
  )
);

const Extractor = React.lazy(() =>
  import("./Containers/Categories/Ventilacion/SubCategoryExtractor/Extractores")
);

function App() {
  const location = useLocation();

  useEffect(() => {
    // Scroll top when location changes
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="App">
      <Layout>
        <Suspense
          fallback={
            <div className="Spinner">
              <Spinner />
            </div>
          }
        >
          <Switch>
            <Route path="/" exact component={Home} />
            <Route
              path="/politica-privacidad"
              exact
              component={PoliticaPrivacidad}
            />
            <Route path="/politica-cookies" exact component={PoliticaCookies} />
            <Route path="/aviso-legal" exact component={AvisoLegal} />
            <Route path="/control-clima" exact component={ControlClima} />
            <Route path="/armarios-cultivo-interior" component={Armarios} />
            <Route path="/generador-co2" exact component={Co2} />
            <Route
              path="/control-clima/controlador-temperatura"
              exact
              component={Temperatura}
            />
            <Route
              path="/control-clima/controlador-humedad"
              exact
              component={Humedad}
            />

            <Route path="/control-clima/armarios" exact component={Armarios} />
            <Route
              path="/control-clima/propagador"
              exact
              component={Propagador}
            />
            <Route
              path="/secadero-cogollos-marihuana"
              exact
              component={Secadero}
            />
            <Route path="/prensa-rosin-barata" exact component={PrensaRosin} />

            <Route path="/iluminacion" exact component={Iluminacion} />
            <Route path="/iluminacion/led" exact component={Led} />
            <Route path="/iluminacion/balastro" exact component={Balastro} />

            <Route path="/ventilacion" exact component={Ventilacion} />
            <Route
              path="/ventilacion/ventilador"
              exact
              component={Ventilador}
            />
            <Route
              path="/ventilacion/aire-acondicionado"
              exact
              component={AireAcondicionado}
            />
            <Route
              path="/ventilacion/extractor-aire"
              exact
              component={Extractor}
            />
            <Route exact component={NotFound404} />
          </Switch>
        </Suspense>
      </Layout>
    </div>
  );
}

export default App;
