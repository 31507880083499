import "./Spinner.css";

const Spinner = () => {
  return (
    <div class="cssload-loading">
      <div class="cssload-loading-circle cssload-loading-row1 cssload-loading-col3"></div>
      <div class="cssload-loading-circle cssload-loading-row2 cssload-loading-col2"></div>
      <div class="cssload-loading-circle cssload-loading-row2 cssload-loading-col3"></div>
      <div class="cssload-loading-circle cssload-loading-row2 cssload-loading-col4"></div>
      <div class="cssload-loading-circle cssload-loading-row3 cssload-loading-col1"></div>
      <div class="cssload-loading-circle cssload-loading-row3 cssload-loading-col2"></div>
      <div class="cssload-loading-circle cssload-loading-row3 cssload-loading-col3"></div>
      <div class="cssload-loading-circle cssload-loading-row3 cssload-loading-col4"></div>
      <div class="cssload-loading-circle cssload-loading-row3 cssload-loading-col5"></div>
      <div class="cssload-loading-circle cssload-loading-row4 cssload-loading-col2"></div>
      <div class="cssload-loading-circle cssload-loading-row4 cssload-loading-col3"></div>
      <div class="cssload-loading-circle cssload-loading-row4 cssload-loading-col4"></div>
      <div class="cssload-loading-circle cssload-loading-row5 cssload-loading-col3"></div>
    </div>
  );
};

export default Spinner;
