import AboutSection from "./AboutSection/AboutSection";
import FeaturedPosts from "./FeaturedPosts/FeaturedPosts";
import FooterMenu from "./FooterMenu/FooterMenu";
import "./FooterPanel.css";

const FooterPanel = () => {
  return (
    <div className="FooterPanel">
      <div className="FooterPanel_Box">
        <FeaturedPosts />
        <AboutSection />
        <FooterMenu />
      </div>
    </div>
  );
};

export default FooterPanel;
