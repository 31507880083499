import MainNavigationMenu from "./MainNavegationMenu/MainNavigationMenu";
import MenuLogo from "./MenuLogo/MenuLogo";
import "./MainMenu.css";
import MobileNavigationMenu from "./MobileNavigationMenu/MobileNavigationMenu";
import { useEffect, useState } from "react";

const MainMenu = () => {
  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);

  const [isDesktop, setIsDektop] = useState(window.innerWidth >= 768);
  const [isScroll, setIsScroll] = useState(false);

  const handleResize = () => {
    if (window.innerWidth >= 768) {
      setIsDektop(true);
    }
    if (window.innerWidth < 768) {
      setIsDektop(false);
    }
  };

  const handleScroll = () => {
    if (window.pageYOffset >= 100) {
      setIsScroll(true);
    }
    if (window.pageYOffset < 100) {
      setIsScroll(false);
    }
  };

  return (
    <div className={isScroll ? "MainMenu_Fixed" : "MainMenu"}>
      <MenuLogo />
      {isDesktop ? <MainNavigationMenu /> : <MobileNavigationMenu />}
    </div>
  );
};

export default MainMenu;
