import "./FeaturedPosts.css";
import FeaturedPostsBox from "./FeaturedPostsBox/FeaturedPostsBox";
import Armario from "../../../../assets/Products/ArmarioInterior/armario-propagador-esquejes.jpg";
import Prensa from "../../../../assets/Products/PrensaRosin/prensa-rosin-automatica.jpg";
import Balastro from "../../../../assets/Products/Balastro/balastro-electronico-600W-cultivo-interior.jpg";

const FeaturedPosts = () => {
  const postTitle1 = "Descubre el mejor armario de cultivo interior | TOP 5";
  const postTitle2 = "Analiza tu prensa de rosín barata manual y automática";
  const postTitle3 = "Compara el mejor balastro electrónico 600W para indoor";

  const postLink1 = "/armarios-cultivo-interior";
  const postLink2 = "/prensa-rosin-barata";
  const postLink3 = "/iluminacion/balastro";

  const imageAlt1 = "armario-cultivo-interior-propagador";
  const imageAlt2 = "prensa-rosin-barata-automatica";
  const imageAlt3 = "balastro-electronico-600w";

  const imageTitle1 = "Armario de cultivo interior propagador";
  const imageTitle2 = "Prensa de rosin automática";
  const imageTitle3 = "Balastro electrónico de 600W";

  return (
    <div className="FeaturedPosts">
      <div className="FeaturedPosts_Title_Box">
        <span className="FeaturedPosts_Title">ÚLTIMAS COMPARATIVAS:</span>
      </div>
      <FeaturedPostsBox
        title={postTitle1}
        image={Armario}
        link={postLink1}
        imageAlt={imageAlt1}
        imageTitle={imageTitle1}
      />
      <FeaturedPostsBox
        title={postTitle2}
        image={Prensa}
        link={postLink2}
        imageAlt={imageAlt2}
        imageTitle={imageTitle2}
      />
      <FeaturedPostsBox
        title={postTitle3}
        image={Balastro}
        link={postLink3}
        imageAlt={imageAlt3}
        imageTitle={imageTitle3}
      />
    </div>
  );
};

export default FeaturedPosts;
