import { useState } from "react";
import MobileMenuIcon from "./MobileMenuIcon/MobileMenuIcon";
import MobileMenuList from "./MobileMenuList/MobileMenuList";
import "./MobileNavigationMenu.css";

const MobileNavigationMenu = () => {
  const [menuDisplayed, setMenuDisplayed] = useState(false);

  return (
    <>
      {!menuDisplayed && (
        <MobileMenuIcon clicked={() => setMenuDisplayed(true)} />
      )}
      {menuDisplayed && (
        <>
          <MobileMenuList clicked={() => setMenuDisplayed(false)} />
        </>
      )}
    </>
  );
};

export default MobileNavigationMenu;
