import "./MobileMenuIcon.css";
import Icon from "../../../../assets/MobileNavigationMenu/mobile-navigation-menu.svg";

const MobileMenuIcon = (props) => {
  return (
    <div className="MenuIcon">
      <img
        src={Icon}
        title="Desplegar y ocultar menú"
        alt="Desplegar y ocultar menú"
        onClick={props.clicked}
      />
    </div>
  );
};

export default MobileMenuIcon;
