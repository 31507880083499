import Footer from "../../Components/Footer/Footer";
import MainMenu from "../../Components/MainMenu/MainMenu";
import TopBar from "../../Components/TopBar/TopBar";

const Layout = (props) => {
  return (
    <>
      <TopBar />
      <MainMenu />
      <main>{props.children}</main>
      <Footer />
    </>
  );
};

export default Layout;
