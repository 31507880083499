import "./MobileMenuList.css";
import Cross from "../../../../assets/MobileNavigationMenu/mobile-cross-menu.svg";
import MobileMenuItem from "./MobileMenuItem/MobileMenuItem";
import mainMenuData from "../../MainNavegationMenu/MainMenuData/MainMenuData";

const MobileMenuList = (props) => {
  return (
    <div className="MobileMenuList">
      <div className="MobileMenuCross">
        <img
          src={Cross}
          title="Cerrar menú"
          alt="Cerrar menú"
          onClick={props.clicked}
        />
      </div>
      <div className="MobileMenuItems">
        <MobileMenuItem data={mainMenuData[0]} clicked={props.clicked} />
        <MobileMenuItem data={mainMenuData[1]} clicked={props.clicked} />
        <MobileMenuItem data={mainMenuData[2]} clicked={props.clicked} />
        <MobileMenuItem data={mainMenuData[3]} clicked={props.clicked} />
        <MobileMenuItem data={mainMenuData[4]} clicked={props.clicked} />
        <MobileMenuItem data={mainMenuData[5]} clicked={props.clicked} />
      </div>
    </div>
  );
};

export default MobileMenuList;
