import { NavLink } from "react-router-dom";
import "./FeaturedPostsBox.css";

const FeaturedPostsBox = (props) => {
  return (
    <NavLink to={props.link}>
      <div className="FeaturedPostsBox">
        <img
          className="FeaturedPostsBox_Image"
          src={props.image}
          alt={props.imageAlt}
          title={props.imageTitle}
        />
        <span>{props.title}</span>
      </div>
    </NavLink>
  );
};

export default FeaturedPostsBox;
