import "./Footer.css";
import Patron from "../../assets/FooterPatron/patron-footer.svg";
import FooterPanel from "./FooterPanel/FooterPanel";

const Footer = () => {
  return (
    <div className="Footer">
      <FooterPanel />
      <div className="CopyrightText">
        <p>© 2021 growdeoro.com</p>
      </div>
      <img
        className="FooterPatron"
        src={Patron}
        title="opiniones para plantar marihuana al mejor precio"
        alt="opiniones para plantar marihuana al mejor precio"
      />
    </div>
  );
};

export default Footer;
