import { useState } from "react";
import MobileNavigationSubMenu from "../../MobileNavigationSubMenu/MobileNavigationSubMenu";
import "./MobileMenuItem.css";

const MobileMenuItem = (props) => {
  const [subMenu, setSubMenu] = useState(false);

  return (
    <>
      <div className="MobileMenuItem">
        <button onClick={() => setSubMenu(true)}>
          {props.data.mobileTitle}
        </button>
      </div>
      {subMenu && (
        <>
          <MobileNavigationSubMenu
            data={props.data}
            clicked={props.clicked}
            backClicked={() => setSubMenu(false)}
          />
        </>
      )}
    </>
  );
};

export default MobileMenuItem;
