import { useState } from "react";
import { NavLink } from "react-router-dom";
import "./MenuItem.css";

const MenuItem = (props) => {
  const [subMenuDisplayed, setSubMenuDisplayed] = useState(false);

  return (
    <div
      onMouseOver={() => setSubMenuDisplayed(true)}
      onMouseLeave={() => setSubMenuDisplayed(false)}
    >
      <div className="MenuItem">
        {props.data.url && (
          <button>
            <NavLink className="Link" to={props.data.url}>
              {props.data.title}
            </NavLink>
          </button>
        )}
        {!props.data.url && (
          <button>
            <span>{props.data.title}</span>
          </button>
        )}
      </div>

      {subMenuDisplayed && props.data.subMenuData && (
        <>
          <ul className={`ItemList_${props.id}`}>
            {props.data.subMenuData.map((item) => (
              <li>
                <button className="SubmenuItems">
                  <NavLink className="Link" to={item.url}>
                    {item.title}
                  </NavLink>
                </button>
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

export default MenuItem;
