import "./MobileNavigationSubMenu.css";
import Arrow from "../../../../assets/MobileNavigationMenu/left-arrow.svg";
import { NavLink } from "react-router-dom";

const MobileNavigationSubMenu = (props) => {
  return (
    <div className="MobileNavigationSubMenu">
      <div className="MobileMenuBack">
        <img
          src={Arrow}
          title="Volver atrás"
          alt="Volver atrás"
          height="35px"
          onClick={props.backClicked}
        />
      </div>
      <div className="MobileSubMenuList">
        {props.data.url && (
          <span className="TitleSubMenuList">
            <NavLink to={props.data.url} onClick={props.clicked}>
              {props.data.mobileSubmenuTitle}
            </NavLink>
          </span>
        )}
        {!props.data.url && (
          <span className="TitleSubMenuList">
            {props.data.mobileSubmenuTitle}
          </span>
        )}
        <ul>
          {props.data.subMenuData &&
            props.data.subMenuData.map((item) => (
              <li>
                <NavLink onClick={props.clicked} to={item.url}>
                  {item.title}
                </NavLink>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default MobileNavigationSubMenu;
