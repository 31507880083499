import { NavLink } from "react-router-dom";
import "./FooterMenu.css";
import GrowingPlant from "../../../../assets/Decoration/growing-plant.gif";

const FooterMenu = () => {
  return (
    <div className="FooterMenu">
      <div className="FooterMenu_Title_Box">
        <span className="FooterMenu_Title">ENLACES LEGALES:</span>
      </div>
      <ul>
        <li>
          <NavLink rel="nofollow" to="/aviso-legal">
            Aviso legal
          </NavLink>
        </li>
        <li>
          <NavLink rel="nofollow" to="/politica-privacidad">
            Política privacidad
          </NavLink>
        </li>
        <li>
          <NavLink rel="nofollow" to="/politica-cookies">
            Política de cookies
          </NavLink>
        </li>
        <li>
          <p>#FelizCosecha</p>
        </li>
      </ul>
      <div>
        <img
          src={GrowingPlant}
          alt="Opiniones y precios para plantar marihuana"
          title="Opiniones y precios para plantar marihuana"
          className="resaltar"
        />
      </div>
    </div>
  );
};

export default FooterMenu;
