import "./AboutSection.css";

const AboutSection = () => {
  return (
    <div className="AboutSection">
      <div className="AboutSection_Title_Box">
        <span className="AboutSection_Title">¿QUÉ ES GROW DE ORO?</span>
      </div>
      <p>
        Grow de Oro recoge opiniones de la comunidad y elabora comparativas para
        plantar tu propia cosecha de marihuana de forma rentable.
      </p>
      <p>
        Las comparativas son 100% objetivas y los enlaces incluidos llevan a
        "Amazon" (productos y tiendas independientes).
      </p>
      <p>
        En calidad de Afiliado de Amazon, esta web obtiene ingresos por las
        compras adscritas que cumplen los requisitos aplicables.
      </p>
    </div>
  );
};

export default AboutSection;
