import "./MenuLogo.css";
import Logo from "../../../assets/MenuLogo/growdeoro-logo.PNG";
import { NavLink } from "react-router-dom";

const MenuLogo = () => {
  return (
    <div className="MenuLogo">
      <NavLink to="/">
        <img src={Logo} title="GrowdeOro" alt="growdeoro-logo" />
      </NavLink>
    </div>
  );
};

export default MenuLogo;
