import "./TopBar.css";
import FocosLed from "../../assets/Decoration/focos-led.png";
import { useEffect, useState } from "react";

const TopBar = () => {
  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);

  const [isDesktop, setIsDektop] = useState(window.innerWidth >= 768);

  const handleResize = () => {
    if (window.innerWidth >= 768) {
      setIsDektop(true);
    }
    if (window.innerWidth < 768) {
      setIsDektop(false);
    }
  };

  return (
    <>
      {isDesktop ? (
        <div
          className="TopBar"
          style={{
            backgroundImage: `url(${FocosLed})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
          }}
        >
          <span>¿Tu Kit de cultivo interior? 🤑</span>
        </div>
      ) : (
        <div className="TopBar">
          <span>¿Tu Kit de cultivo interior? 🤑</span>
        </div>
      )}
    </>
  );
};

export default TopBar;
