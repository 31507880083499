import MenuItem from "./MenuItem/MenuItem";
import "./MainNavigationMenu.css";
import mainMenuData from "./MainMenuData/MainMenuData";

const MainNavigationMenu = () => {
  return (
    <div className="MainNavigationMenu">
      <MenuItem data={mainMenuData[0]} id="A" />
      <MenuItem data={mainMenuData[1]} id="B" />
      <MenuItem data={mainMenuData[2]} id="C" />
      <MenuItem data={mainMenuData[3]} id="D" />
      <MenuItem data={mainMenuData[4]} id="E" />
      <MenuItem data={mainMenuData[5]} id="F" />
    </div>
  );
};

export default MainNavigationMenu;
